<template>
    <Header :isShow="isShow" />
    <div class="warper">
        <div class="content_box" ref="header">
            <div class="content">
                <!-- <p class="title">廉政投诉</p>
                <img class="banner max_big_box" src="@/assets/images/wechart.png" alt="" />
                <div class="line max_big_box"></div> -->
                <div class="form max_small_box">
                    <div class="list" v-for="(item, index) in list" :key="index">
                        <div class="left">
                            <p>{{item.title}}</p>
                        </div>
                        <div class="right">
                            <a-radio-group v-model:value="select[index]" button-style="solid">
                                <a-radio-button value="0">满意</a-radio-button>
                                <a-radio-button value="1">一般</a-radio-button>
                                <a-radio-button value="2">不满意</a-radio-button>
                            </a-radio-group>
                        </div>
                    </div>
                </div>
                <button class="submit" @click="submit">提交</button>
            </div>
        </div>
    </div>
    <!-- <div class="bottom_line"></div> -->
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref, reactive, toRefs } from 'vue'
import { message } from 'ant-design-vue';
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';
import ContactTop from '../../components/contact/ContactTop';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref('')

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//满意度逻辑
const listEffect = () => {
    //列表
    const list = ref()

    const selectAll = reactive({select:[]});

    //获取列表
    const getList = async () => {
        await get('/contact/get_review', {}).then((res) => {
            // console.log('满意度', res)
            list.value = res.data
            for(let i=0;i<res.data.length;i++) {
                selectAll.select.push('0')
            }
        })
    }

    //提交
    const submit = async () => {
        for(let i=0;i<list.value.length;i++){
            await post('/contact/add_review', { id:list.value[i].id, val: selectAll.select[i]}).then((res) => {
                // console.log(res)
            })
        }
        message.success('提交成功');
    }

    const { select } = toRefs(selectAll)

    return { list, getList, select, submit }
}

export default {
    name: 'ContactEvaluation',
    components: { Header, Footer, FloatingFrame, ContactTop },
    setup() {

        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        const { list, getList, select, submit } = listEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        getList()

        return { isShow, header, list, select, submit }
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
.content_box{
    margin-top: 50px;
}
.content{
    @media screen and (max-width: 1200px){
        padding: 0 30px;
    }
    .title{
        color: #2F509E;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }
    .banner{
        width: 100%;
        margin: 0 auto;
        display: block;
        margin-top: 50px;
    }
    .line{
        width: 100%;
        height: 1px;
        background: #bbb;
        margin-top: 50px;
    }
    .form{
        margin-top: 50px;
        .list{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
            @media screen and (max-width: 900px){
                display: block;
            }
            .left{
                width: 48%;
                @media screen and (max-width: 900px){
                    width: 100%;
                }
                p{
                    color: rgba(16, 16, 16, 100);
                    font-size: 16px;
                    @media screen and (max-width: 900px){
                        font-size: .14rem;
                        line-height: .2rem;
                    }
                }
            }
            .right{
                width: 48%;
                display: flex;
                justify-content: flex-end;
                @media screen and (max-width: 900px){
                    width: 100%;
                    justify-content: center;
                    margin-top: 30px;
                }
                .btn{
                    color: rgba(136, 136, 136, 100);
                    font-size: 16px;
                    border: 1px solid #888;
                    padding: 10px 34px;
                    margin-left: 30px;
                    cursor: pointer;
                    &:nth-child(1){
                        background: #2F509E;
                        color: #fff;
                        margin-left: 0px;
                    }
                    @media screen and (max-width: 900px){
                        margin-left: 15px;
                    }
                }
            }
        }
    }
    .submit{
        color: #fff;
        background: #2F509E;
        font-size: 16px;
        border: none;
        padding: 15px 30px;
        width: 150px;
        margin: 0 auto;
        display: block;
        margin-top: 100px;
        cursor: pointer;
        transition: .3s all;
    }
}
.bottom_line{
    width: 100%;
    height: 2px;
    margin-top: 100px;
    background: #BBBBBB;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    background: $content-backgroundcolor;
    border-color: $content-backgroundcolor;
}
</style>